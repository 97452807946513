@import 'assets/styles/core';

.document {
  &__order {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(medium) {
      float: right;
    }

    figcaption {
      width: 200px;
    }

    &__action {
      svg {
        vertical-align: top;
      }
    }
  }
}

.lib-nav {
  &__button {
    display: flex;

    &.prev {
      justify-content: start;
    }

    &.next {
      justify-content: end;
    }

    .wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  &__prev {
    text-align: left;
  }

  &__next {
    text-align: right;
  }

  .button {
    margin: 0;
    height: 100%;
    align-content: center;
    padding: .5rem;
    background-color: transparent;

    &:hover {
      background-color: $alice-blue;
    }

    &.toc {
      border: 1px solid $medium-gray;
    }
  }
}
