@use "sass:meta" as ---6r6i8kqj18t;//  Foundation for Sites Settings
//  -----------------------------

@import '~foundation-sites/scss/util/util';

// Elec
// ---------

$sell: #009;
$buy: #090;
$service: #c00;
$exchange: #c90;
$resume: #60f;
$vacancy: #f60;
$misc: #90c;
$alice-blue: #edf2f5;
$tangerine-yellow: #fecd08;
$site-width-large: 990px;
$site-width-xlarge: 1200px;

// 1. Global
// ---------

$global-font-size: 16px;
$global-width: rem-calc($site-width-xlarge);
$foundation-palette: (
  primary: #2e3191,
  secondary: #3b3b3b,
  success: #31912e,
  warning: #ffc903,
  alert: #912e31,
  alice-blue: $alice-blue,
  san-marino: #5072af,
  tangerine-yellow: $tangerine-yellow,
  grayish-blue: #9fc5d3,
  olivine: #a4ca6a,
);
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8e8e8e;
$black: #000;
$light-black: #313131;
$white: #fff;
$body-font-family: roboto, arial, 'Helvetica Neue', sans-serif;
$global-weight-normal: 400;
$global-weight-bolder: 500;
$global-weight-bold: 700;
$global-radius: 6px;
$global-flexbox: true;
$print-hrefs: false;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 990px,
  xlarge: 1200px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge);

// 4. Base Typography
// ------------------

$header-styles: (
  small: (
    'h1': ('font-size': 24),
    'h2': ('font-size': 20),
    'h3': ('font-size': 16),
    'h4': ('font-size': 15),
    'h5': ('font-size': 14),
    'h6': ('font-size': 13),
  ),
  medium: (
    'h1': ('font-size': 32),
    'h2': ('font-size': 24),
    'h3': ('font-size': 20),
    'h4': ('font-size': 18),
    'h5': ('font-size': 16),
    'h6': ('font-size': 14),
  ),
);
$small-font-color: $dark-gray;
$header-small-font-color: $small-font-color;
$code-background: none;
$code-border: none;
$code-padding: 0;
$anchor-color-hover: map_get($foundation-palette, alert);
$blockquote-color: $light-black;
$blockquote-border: 4px solid map-get($foundation-palette, tangerine-yellow);
$cite-color: $black;

// 5. Typography Helpers
// ---------------------

$lead-font-size: $global-font-size * 1.1;
$subheader-color: $light-black;
$subheader-margin-bottom: 1rem;

// 6. Abide
// --------

$input-error-font-size: rem-calc(14);
$input-error-font-weight: $global-weight-bolder;

// 7. Accordion
// ------------

$accordion-title-font-size: rem-calc(13);

// 10. Breadcrumbs
// ---------------

$breadcrumbs-margin: 0 0 rem-calc(30) 0;
$breadcrumbs-item-font-size: rem-calc(12);
$breadcrumbs-item-color: $light-black;
$breadcrumbs-item-color-current: $dark-gray;
$breadcrumbs-item-color-disabled: $medium-gray;
$breadcrumbs-item-margin: .25rem;
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-separator-color: $dark-gray;

// 11. Button
// ----------

$button-background: #e6e6e6;
$button-color: $black;
$button-color-alt: $white;

// 12. Button Group
// ----------------

$buttongroup-spacing: .5rem;

// 14. Card
// --------

$card-background: transparent;
$card-divider-background: transparent;
$card-border: none;
$card-padding: 0;
$card-margin: 0;

// 17. Dropdown
// ------------

$dropdown-border: 2px solid $medium-gray;

// 18. Dropdown Menu
// -----------------

$dropdownmenu-arrows: false;
$dropdownmenu-arrow-size: 4px;

// 20. Forms
// ---------

$helptext-color: $dark-gray;
$helptext-font-style: normal;
$input-placeholder-color: $dark-gray;
$input-background-disabled: $white;
$input-shadow: none;

// 23. Menu
// --------------

$menu-item-padding: 9px 6px 9px 7px;

// 25. Off-canvas
// --------------

$offcanvas-sizes: (
  small: 260px,
);
$offcanvas-vertical-sizes: (
  small: 62px,
);
$offcanvas-background: $white;
$offcanvas-shadow: 0;

//$offcanvas-push-zindex: 10;
$offcanvas-overlap-zindex: 1030;
$offcanvas-reveal-zindex: 1020;
$offcanvas-transition-length: .4s;
$offcanvas-exit-background: rgba($white, .7);

// 26. Orbit
// ---------

$orbit-bullet-background: scale_color(#fecd08, $lightness: 75%);
$orbit-bullet-background-active: #fecd08;
$orbit-bullet-diameter: 1rem;
$orbit-bullet-margin: .1rem;
$orbit-bullet-margin-top: .8rem;
$orbit-bullet-margin-bottom: .8rem;
$orbit-caption-background: rgb(41 41 41 / 50%);
$orbit-caption-padding: 1.5rem;
$orbit-control-background-hover: rgba($black, .5);
$orbit-control-padding: 1rem;
$orbit-control-zindex: 10;

// 27. Pagination
// --------------

$pagination-font-size: $global-font-size;
$pagination-item-color: $sell;
$pagination-item-padding: rem-calc(2 7);
$pagination-item-background-current: $light-gray;
$pagination-item-color-current: $black;
$pagination-mobile-items: true;

// 49. Switch
// ----------

$switch-background-active: map_get($foundation-palette, san-marino);

// 50. Table
// ---------

$table-is-striped: false;

// 51. Tabs
// --------

$tab-content-border: none;

// 54. Tooltip
// -----------

$has-tip-font-weight: normal;

// Foundation date picker
// -----------

$fdatepicker-ui-primary: map_get($foundation-palette, grayish-blue);
$fdatepicker-ui-color: $black;
$fdatepicker-ui-bg: $white;
$fdatepicker-ui-disabled: $medium-gray;
$fdatepicker-ui-border-color: $medium-gray;
$fdatepicker-ui-border-transparency: none;
$fdatepicker-ui-border: 1px solid $medium-gray;
$fdatepicker-ui-shadow: 0 5px 10px rgba($black, .2);
$fdatepicker-ui-border-radius: $global-radius;
$fdatepicker-ui-before-color: $medium-gray;
$fdatepicker-ui-old-new-color: #999 !default;
$fdatepicker-ui-text-color: $black;
$fdatepicker-inline-width: 220px;

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:279");