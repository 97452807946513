@use "sass:meta" as ---6r6i8kqj18t;.comments {
  &__anonymous {
    border: 1px solid $alice-blue;
    border-radius: $global-radius;
    padding: 1rem;
    font-size: rem-calc(14);

    .action {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .button {
        margin: 0;

        &.login {
          background-color: map-get($foundation-palette, primary);
          color: $white;
        }

        &.registration {
          background-color: $alice-blue;
        }
      }
    }
  }

  &__area {
    @include grid-column-margin;

    .header {
      margin-bottom: 0;

      .title {
        display: flex;

        @include breakpoint(small only) {
          flex-direction: column;
          gap: 8px;
        }

        @include breakpoint(medium) {
          align-items: center;
          justify-content: space-between;
        }
      }

      .wrapper {
        display: flex;
        align-items: baseline;
        gap: 10px;
        font-size: rem-calc(22);
      }

      h3 {
        font-weight: $global-weight-bold;
        margin: 0;

        @include breakpoint(small only) {
          padding: 0;
          background: none;
          border: none;
          font-size: rem-calc(20);
        }
      }

      small {
        color: $medium-gray;
      }
    }

    .avatar {
      @include breakpoint (small only) {
        width: 36px;
        height: 36px;
      }

      @include breakpoint (medium) {
        width: 40px;
        height: 40px;
      }

      &__author {
        flex-shrink: 0;

        @include breakpoint(small only) {
          width: 42px;
        }

        @include breakpoint(medium) {
          width: 90px;
        }
      }

      img {
        border-radius: 50%;
        aspect-ratio: 1/1;
      }
    }

    .create-comment {
      display: flex;
      gap: 20px;

      form {
        width: 100%;
      }

      .action {
        display: flex;
        justify-content: space-between;

        .button {
          margin-bottom: 0;
        }
      }
    }

    .item {
      max-width: 700px;
      margin-bottom: 1rem;

      &__info {
        display: flex;
        align-items: center;
        gap: .5rem;
      }

      &__text {
        word-wrap: break-word;
        margin: 10px 0 8px;
        font-size: rem-calc(14);
      }

      &__footer {
        display: flex;
        align-items: center;
        gap: 20px;
        color: $dark-gray;
        font-size: rem-calc(13);
      }

      .name {
        font-size: rem-calc(16);
        font-weight: bold;
      }

      .company {
        color: $dark-gray;
        font-size: rem-calc(13);
      }

      p {
        line-height: 1.5;
        margin: 0;
      }

      .comment__like {
        display: flex;
        align-items: center;
        gap: 0.2rem;
      }

      svg {
        cursor: pointer;
        height: 16px;
        width: 16px;

        &.is-active {
          fill: $service;
        }
      }

      .create-comment {
        margin: 2rem 0;
      }
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:3717");